import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import axios from '../../../AxiosConfig'
import Logo from '../../images/SkyQ-Tech-logo.svg'
import LeftSidebar from '../Sidebar/LeftSidebar'
import { showLeftSidebar } from '../../../features/sidebar/LeftSidebarSlice'
import { setRenderCount, showPreloader } from '../../../features/data/dataSlice'
import AddTaskPopup from '../Sidebar/AddTaskPopup'
import { showAddTaskModal } from '../../../features/modal/AddTaskSlice'
import Preloader from '../../Pages/smallComponents/Preloader'
import { useNavigate } from 'react-router-dom'
import PinModal from '../Sidebar/PinModal'
import CreateOkrModal from '../Sidebar/CreateOkrModal'
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import TaskCard from '../../Pages/smallComponents/TaskCard'

const Header = ({
  showLeftSidebar,
  userData,
  setRenderCount,
  renderCount,
  attendanceListData,
  showAddTaskModal,
  preloaderVisible,
  showPreloader,
  objectiveData
}) => {
  const [userCurrentLocation, setUserCurrentLocation] = useState('')
  const [latLong, setLatLong] = useState(null)
  const [userLoginStatus, setUserLoginStatus] = useState(false)
  let navigate = useNavigate()
  useEffect(() => {
    const fetchLocation = async () => {
        if ('geolocation' in navigator) {
            try {
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject)
                })

                const { latitude, longitude } = position.coords

                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                )
                const data = await response.json()
                const city =
                    data.address.city ||
                    data.address.town ||
                    data.address.village ||
                    data.address.hamlet ||
                    ''
                const country = data.address.country || ''
                setUserCurrentLocation({ userCurrentLocation: city })
                setLatLong({ 'lat': latitude, 'long': longitude })
            } catch (error) {
                console.error('Error getting location:', error.message)
            }
        } else {
            console.error('Geolocation is not supported by this browser.')
        }
    }
    fetchLocation()
  }, [])
  const handleSubmitLoginLogout = (userID, actionType, username) => {
    showPreloader({preloaderVisible: true})
    let text = "Hi, " + username + "!!\nAre you sure you want to " + actionType;
    if (window.confirm(text) == true) {
        const localDateTime = new Date();
        const moment = require('moment');
        const date = moment();
        const formattedDate = date.format('YYYY-MM-DD');

        const userAgent = navigator.userAgent.toLowerCase();
        let deviceType = "";
        let brand = "";

        // Check for device type
        if (/mobile/i.test(userAgent)) {
            deviceType = "Mobile";
        } else if (/tablet/i.test(userAgent)) {
            deviceType = "Tablet";
        } else if (/iPad|Android/i.test(userAgent) && !/mobile/i.test(userAgent)) {
            deviceType = "Tablet";
        } else if (/macintosh|mac os x|windows/i.test(userAgent)) {
            deviceType = "Desktop";
        }

        if (deviceType == 'Tablet' || deviceType == 'Mobile') {
          // Check for brand
          if (/iphone/i.test(userAgent)) {
              brand = "Apple";
          } else if (/ipad/i.test(userAgent)) {
              brand = "Apple";
          } else if (/macintosh/i.test(userAgent)) {
              brand = "Apple";
          } else if (/android/i.test(userAgent)) {
              brand = "Android";
          } else if (/windows phone/i.test(userAgent)) {
              brand = "Windows Phone";
          } else if (/samsung/i.test(userAgent)) {
              brand = "Samsung";
          } else if (/huawei/i.test(userAgent)) {
              brand = "Huawei";
          } else if (/nokia/i.test(userAgent)) {
              brand = "Nokia";
          } else if (/oneplus/i.test(userAgent)) {
              brand = "OnePlus";
          }
        }

        let checkInOut = {
            UserID: userID,
            Date: formattedDate,
            Location: userCurrentLocation,
            Lat: latLong?.lat,
            Long: latLong?.long,
            Device: deviceType + brand
        }
        if (checkInOut.Lat && checkInOut.Long) {
            axios.put('/attendance', checkInOut).then(res => {
                if (res.data.message === 'Success') {
                    // setShowSuccessModal(true)
                    if (actionType === 'login') {
                        localStorage.setItem('loggedIn', true)
                    } else {
                        localStorage.clear()
                    }
                    setRenderCount({renderCount: parseInt(renderCount) + 1});
                    setTimeout(() => {
                        // setShowSuccessModal(false)
                        // setDisableSubmitButton(false)
                        // navigate('/addTask')
                        window.location.reload();
                    }, 10000);
                    showPreloader({preloaderVisible: false})
                    // setRenderCount({renderCount: parseInt(renderCount) + 1});
                }
            })
        } else {
            // setDisableSubmitButton(false)
            alert('Enable Location to Proceed')
        }
    } else {
        text = "You canceled!";
    }
  }
  useEffect(() => {
      attendanceListData.map((v,k) => {
        if (v.ID == userData.ID) {
          if (v.CheckIn) {
            setUserLoginStatus(true)
          } else {
            setUserLoginStatus(false)
          }
        }
      })
  }, [[attendanceListData]])

  const filteredObjectiveData = useMemo(() => {
    return objectiveData?.filter((arr) => arr.ProjectID === null);
  }, [objectiveData]);
  
  const renderCompanyMissionTooltip = useCallback((props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {filteredObjectiveData?.map((v, k) => {
          if (v.Level !== 5 && v.Level === 1) {
            return (
              <div 
                key={k} 
                className="text-start"
              >
                <TaskCard taskCardData={v} />
              </div>
            );
          }
          return null;
        })}
      </Tooltip>
    );
  }, [filteredObjectiveData]);
  
  return (
    <div>
      <div className="appHeader">
        <div className="row w-100 d-flex align-items-center">
          <div className="w-25 d-flex justify-content-start">
            <a href="#!" className="headerButton d-flex" onClick={() => {showLeftSidebar({leftSidebarVisible: true})}}>
              <ion-icon name="menu-outline" class="fs-3"></ion-icon>  
            </a>
          </div>
          <div className="w-50 dropdown text-center">
            <OverlayTrigger
              placement="bottom"
              overlay={renderCompanyMissionTooltip}
            >
              <img src={Logo} className="img-fluid cursor-pointer" alt="Brand" width="69x69" />
            </OverlayTrigger>
          </div>
          <div class="w-25 d-flex justify-content-end">
            {userLoginStatus &&
              <button class="btn btn-outline-danger d-none d-md-block" onClick={() => { handleSubmitLoginLogout(userData.ID, 'logout', userData.UserName) }}>
                  <ion-icon name="log-out-outline" class="me-0"></ion-icon>
              </button>
            }
            {!userLoginStatus &&
              <button class="btn btn-outline-success d-none d-md-block" onClick={() => { handleSubmitLoginLogout(userData.ID, 'login', userData.UserName) }}><ion-icon name="log-in-outline"></ion-icon></button>
            }
          </div>
        </div>
      </div>
      <LeftSidebar />
      <AddTaskPopup />
      {preloaderVisible &&
        <Preloader />
      }
      <PinModal />
      <CreateOkrModal />
    </div>
  )
}

const mapStateToProps = ({ auth, data }) => {
  const {
    userData
  } = auth
  const {
    renderCount,
    attendanceListData,
    preloaderVisible,
    objectiveData
  } = data
  return {
    userData,
    renderCount,
    attendanceListData,
    preloaderVisible,
    objectiveData
  }
}

const mapDispatchToProps = {
  showLeftSidebar,
  setRenderCount,
  showAddTaskModal,
  showPreloader
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
