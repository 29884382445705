import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Target from '../../images/icon/target.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { showCreateOkrModal } from '../../../features/modal/CreateOkrSlice';
import { showAddTaskModal } from '../../../features/modal/AddTaskSlice';

const TaskCard = ({
    taskCardData,
    objectiveData,
    showCreateOkrModal,
    showAddTaskModal,
    role
}) => {
    const [uid, setUid] = useState(null) 
    const [pid, setPid] = useState(null) 
    const [qid, setQid] = useState(null)
    const [searchParams] = useSearchParams();
    
    let navigate = useNavigate();
    const tooltip = (
        <Tooltip id="progress-tooltip">
            1.2W / 2W
        </Tooltip>
    );
    useEffect(() => {
        setUid(searchParams.get('uid'));
        setPid(searchParams.get('pid'));
        setQid(searchParams.get('qid'));
        // navigate('?pid=' + pid + '&uid=' + uid + '&qid=' + qid);
    }, [uid, pid, qid])
    const handleNextLevel = (data) => {
        // navigate('?pid=' + pid + '&uid=' + uid + '&qid=' + data.ID);
        setQid(data.ID)
    }
    const handleCreateOkr = (data) => {
        if (data.Level !== 4) {
            showCreateOkrModal({createOkrModalVisible: true, parentOkrData: data})
        } else {
            showAddTaskModal({addTaskModalVisible: true, parentOkrData: data})
        }
    }
    return (
        <div class={(taskCardData.Level !== 1) ? `card mb-2 me-sm-2 text-dark cursor-pointer` : `card text-dark cursor-pointer`} style={{backgroundColor: (taskCardData.Level == 2) ? 'gainsboro' : ''}} onClick={() => {handleNextLevel(taskCardData)}}>
            <div class="position-absolute bg-success text-success" style={{top: 0, right: 0, left: 0, height: '3px', width: '23%'}}>.</div>
            <div class="card-body p-1">
                <div class="d-flex flex-column">
                    <span class="fs-6 pb-1">
                        <span class="fw-bold d-flex justify-content-between">
                            <div class="d-flex">
                                {taskCardData.Level === 1 &&
                                    <img src={Target} class="me-1" style={{height: '20px', width: '20px', minHeight: '20px', minWidth: '20px', borderRadius: '100px'}} />
                                }
                                {taskCardData.Level == 2 &&
                                    <span class="bg-success d-flex justify-content-center align-items-center me-1" style={{height: '20px', width: '20px', minHeight: '20px', minWidth: '20px', borderRadius: '100px'}}>Y</span>
                                }
                                {taskCardData.Level == 3 &&
                                    <span class="bg-primary d-flex justify-content-center align-items-center me-1" style={{height: '20px', width: '20px', minHeight: '20px', minWidth: '20px', borderRadius: '100px'}}>Q</span>
                                }
                                {taskCardData.Level == 4 &&
                                    <span class="bg-dark d-flex justify-content-center align-items-center me-1" style={{height: '20px', width: '20px', minHeight: '20px', minWidth: '20px', borderRadius: '100px'}}>S</span>
                                }
                                {taskCardData?.Title}
                            </div>
                            {taskCardData.Level !== 1 &&
                                <span class="badge bg-secondary" style={{fontSize: '9px', height: '16px', minWidth: '60px'}}>Hussain, +4</span>
                            }
                        </span>
                        {role === 2 &&
                            <>
                                {taskCardData.Level !== 1 &&
                                    <div class="divider bg-success mt-2 mb-2" onClick={() => {handleCreateOkr(taskCardData)}}>
                                        <div class="badge bg-success">
                                            <ion-icon name="add-outline"></ion-icon>&nbsp;
                                            {taskCardData.Level === 2 &&
                                                <span>Add Querterly</span>
                                            }
                                            {taskCardData.Level === 3 &&
                                                <span>Add Sprint</span>
                                            }
                                            {taskCardData.Level === 4 &&
                                                <span>Add Task</span>
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </span>
                    <ul class={"ps-2 mt-1 mb-1"}>
                        {objectiveData.map((v,k) => {
                            return <>
                                {v.Status === 0 &&
                                    <>
                                        {taskCardData.ID === v.ParentID &&    
                                            <li class={"cursor-pointer d-flex justify-content-between"}>
                                                <span>
                                                    <ion-icon name="square-outline" class="me-1" style={{minWidth: '15px', marginTop: '5px'}}></ion-icon>
                                                {v.Title} ({v.Score})
                                                </span>
                                                {v.Level === 5 &&
                                                    <span class="badge bg-success" style={{minWidth: '55px'}}>
                                                        <ion-icon name="play"></ion-icon>&nbsp;Play
                                                    </span>
                                                }
                                            </li> 
                                        }
                                    </>
                                }
                                {v.Status === 1 &&
                                    <>
                                        {taskCardData.ID === v.ParentID &&    
                                            <li class={"cursor-pointer d-flex text-muted"} style={{textDecoration: 'line-through', fontSize: 'initial'}}>
                                                <ion-icon name="checkbox-outline" class="me-1" style={{marginTop: '5px'}}></ion-icon>
                                                {v.Title} ({v.Score})
                                            </li> 
                                        }
                                    </>
                                }
                            </>
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ data, auth }) => {
    const {
        objectiveData
    } = data
    const {
        role
    } = auth
    return {
        objectiveData,
        role
    }
}
  
const mapDispatchToProps = {
    showCreateOkrModal,
    showAddTaskModal
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskCard);