import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import axios from '../../AxiosConfig'
import Navigation from '../Pages/smallComponents/Navigation';
import { connect } from 'react-redux';
import { setRefresh, setRenderCount } from '../../features/data/dataSlice';
import jwt_decode from 'jwt-decode'
import { hideAddTaskModal } from '../../features/modal/AddTaskSlice';

const TaskForm = ({ setRefresh, refresh, authToken, setRenderCount, renderCount, hideAddTaskModal, disabledAllButtons, allUserList, allProjectList, parentOkrData }) => {
    console.log(parentOkrData)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showDuration, setShowDuration] = useState(false)
    const [duration, setDuration] = useState('')
    const [showTaskInput, setShowTaskInput] = useState(false)
    const [taskID, setTaskID] = useState('')
    const [userList, setUserList] = useState(null)
    const [userName, setUserName] = useState('')
    const [projectList, setProjectList] = useState(null)
    const [taskList, setTaskList] = useState(null)
    const [userID, setUserID] = useState('')
    const [taskStatus, setTaskStatus] = useState(null)
    const [projectID, setProjectID] = useState(null)
    const [description, setDescription] = useState('')
    const [newTask, setNewTask] = useState('')
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    let navigate = useNavigate()
    const tokenData = jwt_decode(authToken);

    useEffect(() => {
        setTaskID('addTask')
        setShowTaskInput(true)
        setShowDuration(true)
    }, [])
    const handleTaskSubmit = (e) => {
        e.preventDefault();
        setSubmitButtonDisabled(true)
        const moment = require('moment');
        const date = moment();
        const formattedDate = date.format('YYYY-MM-DD');
        const formattedDateTime = date.format('YYYY-MM-DD HH:mm:ss');
        const taskData = {
            'UserID': (parentOkrData) ? null : userID,
            'Status': (parentOkrData) ? 'toDo' : taskStatus,
            'DateTime': formattedDateTime,
            'Date': formattedDate,
            'Duration': duration,
            'ProjectID': (parentOkrData) ? parentOkrData.ProjectID : projectID,
            'TaskID': taskID,
            'Description': description,
            'TaskName': newTask,
            'ObjectiveID': (parentOkrData) ? parentOkrData.ID : null
        }
        axios.put('/entries', taskData).then(res=>{
            if (res.data.message === 'Success') {
                document.getElementById("taskForm").reset()
                setShowSuccessModal(true)
                hideAddTaskModal();
                setTaskStatus(null)
                setTimeout(() => {
                    setSubmitButtonDisabled(false)
                    setShowSuccessModal(false)
                    // navigate('/taskList')
                    setRefresh(refresh + 1)
                }, 2000);
                setRenderCount({renderCount: parseInt(renderCount) + 1})
            }
        })
    }

    useEffect(() => {
        if (taskStatus) {
            document.getElementById('taskFormSubmitButton').click()
        }
    }, [taskStatus])
    useEffect(() => {
        if (allUserList) {
            setUserList(allUserList)
        }
        if (allProjectList) {
            setProjectList(allProjectList)
        }
        axios.get('/tasks').then(res=>{
            if (res.data.message === 'Success') {
                setTaskList(res.data.data)
            }
        })
    }, [allUserList, allProjectList])
    
    useEffect(() => {
        setUserID(tokenData.ID)
    }, [authToken])

    const handleTaskStatus = (taskStatus) => {
        setTaskStatus(taskStatus)
    }
    return (
        <div id="appCapsule" class="mt-0 py-0">
            <div class="d-flex justify-content-center align-items-center h-100 flex-column">
                <div class="section w-100 mt-0 p-2">
                    <div class="container">
                        <form class="d-flex flex-column" id="taskForm" onSubmit={(e) => {handleTaskSubmit(e)}}>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="newTask">Add Task <span class="text-danger">*</span></label>
                                            <textarea id="newTask" rows="4" placeholder="Add task" value={newTask} onChange={(e) => {setNewTask(e.target.value)}} class="form-control" required></textarea>
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                {!parentOkrData &&
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="projectName">Project Name <span class="text-danger">*</span></label>
                                                <select class="form-control form-select" onChange={(e) => {setProjectID(e.target.value)}} id="projectName" required>
                                                    <option value="">Project Name</option>
                                                    {projectList?.map((v,k) => {
                                                        return <option value={v.ID}>{v.Name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div class="col-6 col-md-6">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="requiredTime">Required Time (in hours) <span class="text-danger">*</span></label>
                                            <input type="text" pattern="\d{2}.\d{2}" class="form-control" onChange={(e) => {setDuration(e.target.value)}} value={duration} id="requiredTime" placeholder="HH.MM (eg. 01.30)" autocomplete="off" required />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <button class="btn btn-secondary btn-lg mt-2 me-1 btn-block" onClick={() => {handleTaskStatus('toDo')}} disabled={submitButtonDisabled}>To Do</button>
                                <button class="btn btn-success btn-lg mt-2 btn-block" onClick={() => {handleTaskStatus('onGoing')}} disabled={(disabledAllButtons) ? disabledAllButtons : submitButtonDisabled}>Start Working</button>
                            </div>
                            <button class="btn btn-success btn-lg mt-2" hidden type={'submit'} id="taskFormSubmitButton" disabled={submitButtonDisabled}>Start Working</button>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={showSuccessModal} centered onHide={() => {setShowSuccessModal(false)}}>
                <Modal.Body className="bg-success">Form Submitted Successfully!</Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ data, auth, addTask }) => {
    const {
        refresh,
        renderCount,
        disabledAllButtons,
        allUserList,
        allProjectList
    } = data
    const {
        authToken
    } = auth
    const {
        parentOkrData
    } = addTask
    return {
        refresh,
        authToken,
        renderCount,
        disabledAllButtons,
        allUserList,
        allProjectList,
        parentOkrData
    }
}

const mapDispatchToProps = {
    setRefresh,
    setRenderCount,
    hideAddTaskModal
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);