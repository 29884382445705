import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from 'react-redux'
import { hideLeftSidebar } from '../../../features/sidebar/LeftSidebarSlice'
import { Link } from 'react-router-dom'

const LeftSidebar = ({ hideLeftSidebar, leftSidebarVisible, userData, role }) => {
  return (
    <Offcanvas backdrop={true} show={leftSidebarVisible} onHide={() => {hideLeftSidebar()}}>
      <Offcanvas.Body>
        <div class="profileBox">
          <div class="image-wrapper">
            <img
              src="assets/img/sample/avatar/avatar1.jpg"
              alt="image"
              class="imaged rounded"
            />
          </div>
          <div class="in">
            <strong>{userData.UserName}</strong>
            <div class="text-muted">
              {userData.RoleID == 1 && <span>Super Admin</span>}
              {userData.RoleID == 2 && <span>Admin</span>}
              {userData.RoleID == 3 && <span>User</span>}
            </div>
          </div>
          <a
            href="javascript:void(0)"
            class="close-sidebar-button"
            onClick={() => {
              hideLeftSidebar()
            }}
          >
            <ion-icon name="close"></ion-icon>
          </a>
        </div>
        <ul class="listview flush transparent no-line image-listview mt-5 pt-4">
          {(userData.RoleID === 2 || userData.RoleID === 1) &&
            <li>
              <Link
                to="/userRecord"
                onClick={() => {
                  hideLeftSidebar()
                }}
                class="item p-0 pb-2"
              >
                <div class="icon-box" style={{background: '#F4D02E'}}>
                  <ion-icon name="pie-chart-outline"></ion-icon>
                </div>
                <div class="in">User Report</div>
              </Link>
            </li>
          }
          <li>
            <Link
              to="/ranking"
              class="item p-0 pb-2"
              onClick={() => {
                hideLeftSidebar()
              }}
            >
              <div class="icon-box" style={{background: '#F4D02E'}}>
                <ion-icon name="trophy-outline"></ion-icon>
              </div>
              <div class="in">Rankings</div>
            </Link>
          </li>
          <li>
            <Link
              to="/okrDashboard"
              onClick={() => {
                hideLeftSidebar()
              }}
              class="item p-0 pb-2"
            >
              <div class="icon-box bg-primary">
                <ion-icon name="speedometer-outline"></ion-icon>
              </div>
              <div class="in">OKR</div>
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard"
              onClick={() => {
                hideLeftSidebar()
              }}
              class="item p-0 pb-2"
            >
              <div class="icon-box bg-primary">
                <ion-icon name="briefcase-outline"></ion-icon>
              </div>
              <div class="in">Work</div>
            </Link>
          </li>
          <li>
            <Link
              to="/Conversations"
              class="item p-0 pb-2"
              onClick={() => {
                hideLeftSidebar()
              }}
            >
              <div class="icon-box bg-primary">
                <ion-icon name="chatbubbles-outline"></ion-icon>
              </div>
              <div class="in">Messaging</div>
            </Link>
          </li>
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

const mapStateToProps = ({ leftSidebar, auth }) => {
  const { leftSidebarVisible } = leftSidebar
  const { userData, role } = auth
  return {
    leftSidebarVisible,
    userData,
    role
  }
}

const mapDispatchToProps = {
  hideLeftSidebar
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar)
