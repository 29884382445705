import React from 'react'
import './UserChatView.css'
import { Link } from 'react-router-dom'

function UserChatView({}) {
  return (
    <div className="position-relative UserChatView">
      <div class="chatHeader">
        <Link
          to="/conversation"
          className="btn btn-outline-primary outline-none border-0 btn-sm px-0 d-flex align-items-center justify-content-center d-sm-none"
        >
          <ion-icon name="chevron-back-outline"></ion-icon>
        </Link>
        <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
        <div className="in">
          <div>Juan Esteban Sarmiento</div>
          {/* <span className="text-muted">Edit</span> */}
        </div>
      </div>
      <div>
        <div class="message-divider">Friday, Sep 20, 10:40 AM</div>

        <div class="message-item">
          <img
            src="assets/img/sample/avatar/avatar1.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">John</div>
            <div class="bubble">Hi everyone, how are you?</div>
            <div class="footer">8:40 AM</div>
          </div>
        </div>

        <div class="message-item">
          <img
            src="assets/img/sample/avatar/avatar2.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">Marry</div>
            <div class="bubble">
              I'm fine, how are you today john, do you feel good?
            </div>
            <div class="footer">10:40 AM</div>
          </div>
        </div>

        <div class="message-item user">
          <div class="content">
            <div class="bubble">
              Would you please repost the photo you sent yesterday?
            </div>
            <div class="footer">10:40 AM</div>
          </div>
        </div>

        <div class="message-divider">Friday, Sep 20, 10:40 AM</div>

        <div class="message-item">
          <img
            src="assets/img/sample/avatar/avatar2.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">Marry</div>
            <div class="bubble">
              <img
                src="assets/img/sample/photo/1.jpg"
                alt="photo"
                class="imaged w160"
              />
            </div>
            <div class="footer">10:40 AM</div>
          </div>
        </div>

        <div class="message-item">
          <img
            src="assets/img/sample/avatar/avatar4.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">Katie</div>
            <div class="bubble">Nice photo !</div>
            <div class="footer">10:40 AM</div>
          </div>
        </div>

        <div class="message-item">
          <img
            src="assets/img/sample/avatar/avatar2.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">Marry</div>
            <div class="bubble">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              vitae nisl et nibh iaculis sagittis. In hac habitasse platea
              dictumst. Sed eu massa lacinia, interdum ex et, sollicitudin elit.
            </div>
            <div class="footer">10:40 AM</div>
          </div>
        </div>

        <div class="message-item user">
          <div class="content">
            <div class="bubble">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              vitae nisl et nibh iaculis sagittis. In hac habitasse platea
              dictumst. Sed eu massa lacinia, interdum ex et, sollicitudin elit.
            </div>
            <div class="footer">10:40 AM</div>
          </div>
        </div>
      </div>

      <div class="chatFooter">
        <form>
          <a
            href="#"
            class="btn btn-icon btn-secondary rounded"
            data-bs-toggle="offcanvas"
            data-bs-target="#actionSheetAdd"
          >
            <ion-icon name="add"></ion-icon>
          </a>
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                placeholder="Type a message..."
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>
          <button type="button" class="btn btn-icon btn-primary rounded">
            <ion-icon name="send"></ion-icon>
          </button>
        </form>
      </div>
    </div>
  )
}

export default UserChatView
